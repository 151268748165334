import React, { useEffect, useState } from "react";

import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../../globalStyles";
import {
  HamburgerIcon,
  Nav,
  NavbarContainer,
  NavBtnLink,
  // NavItem,
  // NavLinks,
  NavItemBtn,
  NavLogo,
  NavMenu,
} from "./Navbar.elements";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  // const [homeClick, setHomeClick] = useState(false);
  // const [servicesClick, setServicesClick] = useState(false);
  // const [productsClick, setProductsClick] = useState(false);

  // const handleHomeClick = () => {
  //   setHomeClick(true);
  //   setProductsClick(false);
  //   setServicesClick(false);
  // };
  // const handleServicesClick = () => {
  //   setHomeClick(false);
  //   setProductsClick(false);
  //   setServicesClick(true);
  // };
  // const handleProductsClick = () => {
  //   setHomeClick(false);
  //   setProductsClick(true);
  //   setServicesClick(false);
  // };

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    // so if the screensize is <= 960px then set button state to false
    if (window.innerwidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const scrollToFooter = () => {
    const footer = document.getElementById("footer");
    if (footer) {
      console.log(footer.offsetTop);
      window.scrollTo({
        top: footer.offsetTop,
        behavior: "smooth",
      });
    }
  };
  const handleButtonClick = () => {
    closeMobileMenu();
    scrollToFooter();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/">
              {/* <NavIcon /> */}
              <img
                src={require("../../images/sklogo.jpg")}
                alt="logo"
                height={60}
                width={60}
                style={{ paddingBottom: 5 }}
              />
              SAWARIKAR
            </NavLogo>
            <HamburgerIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </HamburgerIcon>
            <NavMenu onClick={handleClick} click={click}>
              {/* <NavItem onClick={handleHomeClick} homeClick={homeClick}>
                <NavLinks to="/" onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>

              <NavItem
                onClick={handleServicesClick}
                servicesClick={servicesClick}
              >
                <NavLinks to="/services" onClick={closeMobileMenu}>
                  Services
                </NavLinks>
              </NavItem>

              <NavItem
                onClick={handleProductsClick}
                productsClick={productsClick}
              >
                <NavLinks to="/Products" onClick={closeMobileMenu}>
                  Products
                </NavLinks>
              </NavItem> */}

              <NavItemBtn>
                {button ? (
                  <Button primary onClick={handleButtonClick}>
                    LET'S JOIN
                  </Button>
                ) : (
                  // <NavBtnLink to="/sign-up">
                  // </NavBtnLink>
                  <NavBtnLink to="/sign-up">
                    <Button onClick={closeMobileMenu} fontBig primary>
                      SIGN UP
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
