import React, { useState } from "react";
import { InfoSection } from "../../components";
import { homeObjOne } from "./Data";

function SignUp() {
  const [formData, setFormData] = useState({
    // Initialize the form data fields
    firstName: "",
    lastName: "",
  });

  const handleInputChange = (e) => {
    // Update the form data when input fields change
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignUp = () => {
    // Handle signup logic here, you can send the form data to your backend or perform any other actions
    console.log("Form data submitted:", formData);
  };
  return (
    <>
      <InfoSection {...homeObjOne}>
        <form>
          <label>
            First Name:
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <label>
            Last Name:
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <button type="button" onClick={handleSignUp}>
            {homeObjOne.buttonLabel}
          </button>
        </form>
      </InfoSection>
    </>
  );
}

export default SignUp;
