import axios from "axios";
import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { Button } from "../../globalStyles";
import { NavLogo } from "../Navbar/Navbar.elements";
import {
  FooterContainer,
  // FooterSubText,
  FooterSubHeading,
  FooterSubscription,
  Form,
  FormInput,
  SocialIconLink,
  SocialIcons,
  // FooterLinksContainer,
  // FooterLinksWrapper,
  // FooterLinkItems,
  // FooterLinkTitle,
  // FooterLink,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./Footer.elements";

function Footer() {
  const date = new Date();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://api.sawarikar.com/api/v1/landing-page",
        {
          fullName: name,
          phoneNumber: phoneNumber,
          response: selectedOption,
        }
      );

      console.log("API response:", response.data);
      setResponseMessage(response.data.message);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 1000);
      setName("");
      setPhoneNumber("");
      setSelectedOption("");
    } catch (error) {
      // Handle errors, e.g., display an error message
      console.error("Error submitting form:", error);
    }
  };

  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
          Join Us Pre-Launch for Exclusive Offers and Promotions!
        </FooterSubHeading>
        {/* <FooterSubText>You can unsubscribe at any time.</FooterSubText> */}
        <Form className="signupform" onSubmit={handleFormSubmit}>
          {/* <FormInput
            name="email"
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={handleEmailChange}
          /> */}
          <FormInput
            name="name"
            type="text"
            placeholder="Enter Your Name"
            value={name}
            onChange={handleNameChange}
          />
          <FormInput
            name="phoneNumber"
            type="tel"
            placeholder="Enter Your Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <select
            className="dropdown"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="">
              Are you onboard with us with 0% commission rides?
            </option>
            <option value="agree">YES!</option>
            <option value="disagree">NO!</option>
          </select>
          <Button>Get Connect</Button>
          {showPopup && <div className="popup">{responseMessage}</div>}
        </Form>
      </FooterSubscription>
      {/* <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>About Us</FooterLinkTitle>
            <FooterLink to="/sign-up">How it works</FooterLink>
            <FooterLink to="/">Testimonials</FooterLink>
            <FooterLink to="/">Careers</FooterLink>
            <FooterLink to="/">Investors</FooterLink>
            <FooterLink to="/">Terms of Service</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Contact Us</FooterLinkTitle>
            <FooterLink to="/">Contact</FooterLink>
            <FooterLink to="/">Support</FooterLink>
            <FooterLink to="/">Destinations</FooterLink>
            <FooterLink to="/">Sponsorships</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Videos</FooterLinkTitle>
            <FooterLink to="/">Submit Video</FooterLink>
            <FooterLink to="/">Ambassadors</FooterLink>
            <FooterLink to="/">Agency</FooterLink>
            <FooterLink to="/">Influencer</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Social Media</FooterLinkTitle>
            <FooterLink to="/">Instagram</FooterLink>
            <FooterLink to="/">Facebook</FooterLink>
            <FooterLink to="/">Youtube</FooterLink>
            <FooterLink to="/">Twitter</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer> */}
      <SocialMedia>
        <SocialMediaWrap>
          <NavLogo to="/">
            {/* <NavIcon /> */}
            <img
              src={require("../../images/sklogo.jpg")}
              alt="logo"
              height={60}
              width={60}
              style={{ paddingBottom: 5 }}
            />
            SAWARIKAR
          </NavLogo>
          <WebsiteRights>SAWARIKAR © {date.getFullYear()} </WebsiteRights>
          <SocialIcons>
            <SocialIconLink
              href="https://www.facebook.com/profile.php?id=61561285010032"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.instagram.com/sawarikarorg"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </SocialIconLink>
            {/* <SocialIconLink href="/" target="_blank" aria-label="YouTube">
              <FaYoutube />
            </SocialIconLink> */}
            <SocialIconLink href="/" target="_blank" aria-label="Twitter">
              <RiTwitterXFill />
            </SocialIconLink>
            <SocialIconLink href="/" target="_blank" aria-label="LinkedIn">
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
