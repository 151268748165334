import React from "react";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
} from "./InfoSection.elements";
import { Container, Button } from "../../globalStyles";
// import { Link } from "react-router-dom";

const InfoSection = ({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
}) => {
  const scrollToFooter = () => {
    const footer = document.getElementById("footer");
    if (footer) {
      console.log(footer.offsetTop);
      window.scrollTo({
        top: footer.offsetTop,
        behavior: "smooth",
      });
    }
  };
  const handleButtonClick = () => {
    // closeMobileMenu();
    scrollToFooter();
  };
  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow
            imgStart={imgStart}
            style={{ display: "flex", alignItems: "center" }}
          >
            <InfoColumn>
              <TextWrapper>
                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                {/* <Link to='/sign-up'> */}
                <Button
                  big
                  fontBig
                  primary={primary}
                  onClick={handleButtonClick}
                >
                  {buttonLabel}
                </Button>
                {/* </Link> */}
              </TextWrapper>
            </InfoColumn>
            {!!img && (
              <InfoColumn>
                <ImgWrapper start={start}>
                  <Img src={img} alt={alt} />
                </ImgWrapper>
              </InfoColumn>
            )}
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
};

export default InfoSection;
