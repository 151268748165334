export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Revolutionizing Journeys",
  headline: `Book a ride with \n Zero Commision`,
  description: `App by the drivers for the people. \n
100% direct payment to drivers.`,
  buttonLabel: "Join Free",
  imgStart: "",
  img: require("../../images/manOnCarMoney.svg"),
  alt: "Credit Card",
  start: "",
};

export const homeObjTwo = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "You Pay Less Drivers Earn More",
  description: `Sawarikar is a Direct-to-Driver app. There is no commission or middle-men. What you pay goes 100% to the Driver and his family!`,
  buttonLabel: "Download App",
  imgStart: "",
  img: require("../../images/driver.png"),
  alt: "Vault",
  start: "",
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Open, Hi-Tech, Sustainable",
  description:
    "100% Open data. Built on open mobility platform.",
  buttonLabel: "Explore Open Data",
  imgStart: "start",
  img: require("../../images/svg-3.svg"),
  alt: "Vault",
  start: "true",
};
